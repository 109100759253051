@media (max-width: 768px) {
	.form-grid-container {
		overflow-x: auto;
	}

}

.page-title .title-area {
	min-width: 0;
}

.page-title .title-area div:first-child {
	min-width: 0;
}

.editable-row textarea.form-control {
	height: 38px !important;
}

.grid-heading-row .row-index {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}


.el-date-picker__next-btn button,
.el-date-picker__prev-btn button {
	line-height: 100%;
}
